import React from 'react';
import '../../assets/bootstrap/bootstrap.min.css'
import '../../assets/css/aos.css'
import '../../assets/css/custom.css';
import '../../assets/css/mobile.css';
import '../../assets/css/owl.carousel.css';
import '../../assets/css/custom-style.css';
import '../../assets/css/responsive.css';
import '../../assets/css/special-classes.css';
// Import images
import enhancedImg from '../../assets/images/Homeimages/enhanced.webp';
import cervicalscreeningImg from '../../assets/images/Homeimages/cer.webp';
import longcovidImg from '../../assets/images/Homeimages/longcovid.webp';
import carehomemedicinesImg from '../../assets/images/Homeimages/carehomemedicines.webp';
import carehomeImg from '../../assets/images/Homeimages/carehome.webp';
import vaccinationImg from '../../assets/images/Homeimages/vaccination.webp';
import workflowImg from '../../assets/images/Homeimages/workflow.webp';
import prescriptionImg from '../../assets/images/Homeimages/Prescription.webp';
import claimsImg from '../../assets/images/Homeimages/claimservice.webp';
import secretarialImg from '../../assets/images/Homeimages/seceteriat.webp';
import practicesupportImg from '../../assets/images/Homeimages/practicesupport.webp';
import telephoneImg from '../../assets/images/Homeimages/reception.webp';
import core from '../../assets/images/Homeimages/corenavi.webp';
import peoplemanagementImg from '../../assets/images/Homeimages/peoplemanagement.webp';
import prof from '../../assets/images/Homeimages/professionaltraining.webp';

import { Link } from 'react-router-dom';
const ListServiceSection = () => {
  return (
    <section className="index3-service-section w-100 float-left padding-top padding-bottom">
      <div className="container">
        <div className="generic-title text-center">
          <span className="small-text" data-aos="fade-up" data-aos-duration="700">Our Services</span>
          <h2 data-aos="fade-up" data-aos-duration="700">What We Can Do Together</h2>
          <br /><br /><br /><br />
          <h2 data-aos="fade-up" data-aos-duration="700">Clinical Services</h2>
        </div>
        <div className="index3-service-con">
          <ServiceItem
            imgSrc={enhancedImg}
            title="Enhanced Access GP Clinics"
            description="Enhanced Access GP Clinics offer patients greater flexibility and..."
            link="/enhancedaccess"
          />
          <ServiceItem
            imgSrc={cervicalscreeningImg}
            title="Cervical Screening"
            description="Cervical screening is a vital preventive healthcare measure aimed at detecting ..."
            link="/cervicalscreening"
          />
          <ServiceItem
            imgSrc={longcovidImg}
            title="Long Covid Service"
            description="LPC provide essential representation on the Long Covid Service Multidisciplinary ..."
            link="/longcovid"
          />
          <ServiceItem
            imgSrc={carehomemedicinesImg}
            title="Care Home Medicines Training"
            description="Our organisation specializes in delivering comprehensive medicines-based ..."
            link="/homecaremedicine"
          />
          <ServiceItem
            imgSrc={carehomeImg}
            title="Care Home Virtual Ward Rounds"
            description="Experience proactive and personalised care for residents with our Care..."
            link="/virtualcarehome"
          />
          <ServiceItem
            imgSrc={vaccinationImg}
            title="Vaccination Service"
            description="Our Vaccination Service is a comprehensive solution dedicated to safeguarding the health and well-being ..."
            link="/vaccination"
          />
        </div>

        <div className="generic-title text-center">
          <br /><br /><br /><br />
          <h2 data-aos="fade-up" data-aos-duration="700">Administrative Services</h2>
        </div>
        <div className="index3-service-con">
          <ServiceItem
            imgSrc={workflowImg}
            title="Workflow Hub"
            description="Our Workflow Hub is a centralised solution for streamlined processing of clinical ..."
            link="/workflow"
          />
          <ServiceItem
            imgSrc={prescriptionImg}
            title="Prescription Hub"
            description="The Prescription Hub is your central solution for streamlined ordering and management ..."
            link="/prescriptionhub"
          />
          <ServiceItem
            imgSrc={claimsImg}
            title="Claims Service"
            description="Empower your GP practice with our Claims Service, a centralised solution dedicated to ..."
            link="/claimservice"
          />
          <ServiceItem
            imgSrc={secretarialImg}
            title="Secretarial Hub"
            description="The Secretarial Hub is your centralised solution for efficient referral letter ..."
            link="/secretarialhub"
          />
          <ServiceItem
            imgSrc={practicesupportImg}
            title="Practice Support Service"
            description="Our Practice Support Team is the backbone of efficiency and reliability for your GP ..."
            link="/practicesupport"
          />
          <ServiceItem
            imgSrc={telephoneImg}
            title="Reception Support Service"
            description="Our Reception Support Team is the backbone of efficiency and reliability for your GP ..."
            link="/receptionsupport"
          />
        </div>

        <div className="generic-title text-center">
          <br /><br /><br /><br />
          <h2 data-aos="fade-up" data-aos-duration="700">Professional Development and Training</h2>
        </div>
        <div className="index3-service-con">
          <ServiceItem
            imgSrc={prof}
            title="Professional Training (CPD accredited)"
            description="Our Professional Training (CPD Accredited) Service is designed to support the ..."
            link="/professionaltraining"
          />
          <ServiceItem
            imgSrc={core}
            title="Care Navigation Training"
            description="Our Navigation Training (CPD Accredited) Service is designed to support the ..."
            link="/corenavigation"
          />
          <ServiceItem
            imgSrc={claimsImg}
            title="Quality Contact Support Service"
            description="..."
            link="/services"
          />
        </div>

        <div className="generic-title text-center">
          <br /><br /><br /><br />
          <h2 data-aos="fade-up" data-aos-duration="700">Workforce Support</h2>
        </div>
        <div className="index3-service-con">
          <ServiceItem
            imgSrc={workflowImg}
            title="Workforce Deployment & Support Service"
            description="..."
            link="/workforce"
          />
          <ServiceItem
            imgSrc={peoplemanagementImg}
            title="People Management Services"
            description="Our People Management Services Team is where expertise meets compassion..."
            link="/peoplemanage"
          />
          <ServiceItem
            imgSrc={claimsImg}
            title="ARRS"
            description="As the primary employer on behalf of all our Primary Care Networks (PCNs), we ..."
            link="/arrs"
          />
        </div>
      </div>
    </section>
  );
};

const ServiceItem = ({ imgSrc, title, description, link }) => (
  <div className="item service-type-box service-img-mobile-css">
    <div className="treatment-types text-center">
      <figure>
        <img src={imgSrc} alt={`${title} image`} />
      </figure>
      <h4>{title}</h4>
      <p>{description}</p>
      <Link to={link}>Read More <i className="fas fa-angle-right"></i></Link>
    </div>
  </div>
);

export default ListServiceSection;
