import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import img1 from "../../assets/images/Homeimages/claimservice.webp"
import img2 from "../../assets/images/Homeimages/workflow.webp"
import img3 from "../../assets/images/Homeimages/Prescription.webp"
import img4 from "../../assets/images/Homeimages/longcovid.webp"
import img5 from "../../assets/images/Homeimages/carehomemed.webp"
import img6 from "../../assets/images/Homeimages/peoplemanagement.webp"
import img7 from "../../assets/images/Homeimages/practicesupport.webp"
import img8 from "../../assets/images/Homeimages/carehomemedicines.webp"
import img9 from "../../assets/images/Homeimages/supportingpcn.jpg"
import img10 from "../../assets/images/Homeimages/carehomemedicines.webp"
import img11 from "../../assets/images/Homeimages/arrs.webp"
// import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { MdOutlineKeyboardArrowLeft,MdKeyboardArrowRight } from "react-icons/md";
import { Link } from "react-router-dom";

const items = [
  {
    imgSrc: img1,
    altText: "treatment-img2",
    title: "Secretarial Hub",
    description: "The Secretarial Hub is your centralised solution for efficient referral ...",
    link: "/secretarialhub",
  },
  {
    imgSrc: img2,
    altText: "treatment-img3",
    title: "Workflow Hub",
    description: "Our Workflow Hub is a centralised solution for streamlined processing...",
    link: "/workflow",
  },
  {
    imgSrc: img3,
    altText: "treatment-img4",
    title: "Prescription Hub",
    description: "The Prescription Hub is your central solution for streamlined ordering ...",
    link: "/prescriptionhub",
  },
  {
    imgSrc: img4,
    altText: "treatment-img5",
    title: "Long Covid Service",
    description: "LPC provide essential representation on the Long Covid Service ...",
    link: "/longcovid",
  },
  {
    imgSrc: img5,
    altText: "treatment-img6",
    title: "Care Home Virtual Ward Rounds",
    description: "Experience proactive and personalised care for residents with our Care...",
    link: "/virtualcarehome",
  },
  {
    imgSrc: img6,
    altText: "treatment-img7",
    title: "People Management Services",
    description: "Our People Management Services Team is where expertise meets ...",
    link: "/peoplemanage",
  },
  {
    imgSrc: img7,
    altText: "treatment-img8",
    title: "Practice Support Service",
    description: "Our Practice Support Team is the backbone of efficiency and reliability ...",
    link: "/practicesupport",
  },
  {
    imgSrc: img8,
    altText: "treatment-img9",
    title: "Care Home Medicines Training",
    description: "Our organisation specializes in delivering comprehensive  ...",
    link: "/homecaremedicine",
  },
  {
    imgSrc: img9,
    altText: "treatment-img10",
    title: "Supporting PCNs",
    description: "PCNs, or Primary Care Networks, are fundamental components of the NHS ...",
    link: "/services",
  },
  {
    imgSrc: img11,
    altText: "treatment-img11",
    title: "ARRS",
    description: "As the primary employer on behalf of all our Primary ...",
    link: "/services",
  },
];

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1024 },
    items: 3,
  },
  desktop: {
    breakpoint: { max: 1024, min: 768 },
    items: 2,
  },
  tablet: {
    breakpoint: { max: 768, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
const CustomControls = ({ previous, next }) => {
    return (
      <div className="space-x-4" style={{display: "flex" ,justifyContent: "center", marginTop:"18px"}}>
        <button
          onClick={previous}
          className="p-2 "
          style={{border: "none", outline: "none" ,background: "none", color: "#298d9c", cursor:"pointer"}}
        >
          <MdOutlineKeyboardArrowLeft className="" style={{fontSize: "55px"}}/>
        </button>
        <button
          onClick={next}
          className="p-2"
          style={{border: "none",outline: "none" ,background: "none", color: "#298d9c", cursor:"pointer"}}
        >
          <MdKeyboardArrowRight style={{fontSize: "55px"}} />
        </button>
      </div>
    );
  };
  const PortfolioCarousel = () => {
    return (
      <div>
        <Carousel
          responsive={responsive}
          customButtonGroup={<CustomControls />}
          arrows={false}
          renderButtonGroupOutside={true} // Add this line to render buttons outside the carousel
        >
          {items.map((item, index) => (
            <div key={index} className="item p-md-1">
              <div className="treatment-types text-center p-4">
                <figure >
                  <img src={item.imgSrc} alt={item.altText} className="w-full h-auto" style={{borderRadius: "15px"}} />
                </figure>
                <h4 className="text-xl font-semibold mt-4">{item.title}</h4>
                <p className="mt-2 text-gray-600">{item.description}</p>
                <Link to={item.link} className="text-blue-500 mt-4 inline-block">
                  Read More <i className="fas fa-angle-right"></i>
                </Link>
              </div>
            </div>
          ))}
        </Carousel>
        {/* <CustomControls
          previous={() => document.querySelector('.react-multi-carousel-item--active .react-multi-carousel-arrow--left').click()}
          next={() => document.querySelector('.react-multi-carousel-item--active .react-multi-carousel-arrow--right').click()}
        /> */}
      </div>
    );
  };;

export default PortfolioCarousel;




