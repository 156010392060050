import React from 'react';
import video from '../assets/BannerBgVideo/bg1.mp4';
import '../assets/bootstrap/bootstrap.min.css'
import '../assets/css/aos.css'
import '../assets/css/custom.css';
import '../assets/css/mobile.css';
// import '../assets/css/custom-style.css';
import '../assets/css/owl.carousel.css';
import { Link } from 'react-router-dom';
import "./services.css"
function BannerSectionfed() {
    const scrollToSection = (id) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };
    return (
        <section className="banner-main-section w-100 float-left d-flex align-items-center">
            <video className="video-slide" src={video} autoPlay loop muted></video>
            <div className="container-fluid">
                <div className="banner-inner-con">
                    <div className="banner-txt-section ">
              
                        <h1 style={{fontSize: "70px"}} className='hometitlee'>Welcome to FEDERATE LPC’s bespoke technology platform</h1>
                        <div className="banner-btns generic-btn mt-5" >
                            <Link to={"https://lpcfederate.co.uk/about.html"} target='_blank'>Download Our App</Link>
                
                        </div>
                    {/* </div> */}
                    {/* 
                    <div className="banner-images-con" data-aos="fade-up" data-aos-duration="700">
                        <figure className="mb-0">
                            <img src="assets/images/banner-right-img1.png" alt="banner-right-img1" />
                        </figure>
                        <figure className="mb-0">
                            <img src="assets/images/banner-right-img2.png" alt="banner-right-img2" />
                        </figure>
                        <figure className="mb-0">
                            <img src="assets/images/banner-right-img3.png" alt="banner-right-img3" />
                        </figure>
                   
                    */}
                     </div>
                </div>
                <div class="social-links" data-aos="fade-up" data-aos-duration="700">
                <ul class="list-unstyled mb-0">
                    <li><a class="facebook" href="https://www.facebook.com/BlackburnLocalPrimaryCare" target="_blank" ><i class="fab fa-facebook-f"></i></a>
                    </li>
                    <li><a class="twitter"
                            href="https://twitter.com/lpcfederation?lang=en" target="_blank"><i
                                class="fab fa-twitter"></i></a></li>
                    <li class="mb-0"><a class="instagram"
                            href="https://www.instagram.com/lpcfederation/" target="_blank"><i
                                class="fab fa-instagram"></i></a>
                    </li>
                </ul>
            </div>
            </div>
        </section>
    );
}

export default BannerSectionfed;
