import React from 'react';
// import ServicesSideSection from './ServicesSideSection'; // Assuming ServicesSideSection is another React component
import '../../assets/bootstrap/bootstrap.min.css'
import '../../assets/css/aos.css'
import '../../assets/css/custom.css';
import '../../assets/css/mobile.css';
import '../../assets/css/owl.carousel.css';
import '../../assets/css/custom-style.css';
import '../../assets/css/responsive.css';
import '../../assets/css/special-classes.css';
import careHomeMedicinesImg from '../../assets/images/Homeimages/arrs.webp';
import ServiceSideSection from '../serviceSideSection';
import BannerDynammic from '../BannerD';


const Arrs = () => {
  return (
    <>
<BannerDynammic had1={"ARRS"}/>
<section className="singleblog-section blogpage-section">
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-sm-12 col-12">
            <div className="main-box">
              <figure className="image1" data-aos="fade-up" data-aos-duration="700">
                <img src={careHomeMedicinesImg} alt="" className="img-fluid" />
              </figure>
              <div className="content1" data-aos="fade-up" data-aos-duration="700">
  
  <p className="text-size-16">
    As the primary employer on behalf of all our Primary Care Networks (PCNs),
    we specialise in providing tailored recruitment and mobilisation services
    for additional roles within practices. Our commitment is to ensure a
    seamless integration process through bespoke induction programs designed to
    meet the unique needs of each role and context. <br />
    <br />
    At the heart of our mission is the goal to strengthen primary care by
    bolstering both capacity and capability within general practice. By
    facilitating the smooth integration of new roles into practice teams from
    the outset, we can direct our focus towards delivering exceptional patient
    care, nurturing ongoing professional development, and prioritising an
    outcomes-based approach to delivery. <br />
    <br />
    Additional roles include:
  </p>
  <ul>
    <li>Social Prescribing Link Workers</li>
    <li>Clinical Pharmacists</li>
    <li>Pharmacy Technicians</li>
    <li>Physician Associates</li>
    <li>Nursing Associates</li>
    <li>Trainee Nursing Associates</li>
    <li>Advanced Clinical Practitioners</li>
    <li>Health &amp; Wellbeing coaches</li>
    <li>Digital Lead</li>
    <li>Mental Health Practitioners (in partnership with LSCFT)</li>
  </ul>
  <p />






              </div>
            </div>
          </div>
          {/* <div className="col-lg-4 col-md-12 col-sm-12 col-12 column">
          </div> */}
        </div>
      </div>
      <ServiceSideSection />

    </section>
     </>

  );
};

export default Arrs;
