import React from 'react';
import counterImg1 from '../../assets/images/counter-img1.png';
import counterImg2 from '../../assets/images/counter-img2.png';
import counterImg3 from '../../assets/images/counter-img3.png';
import counterImg4 from '../../assets/images/counter-img4.png';
import '../../assets/bootstrap/bootstrap.min.css'
import '../../assets/css/aos.css'
import '../../assets/css/custom.css';
import '../../assets/css/mobile.css';
import '../../assets/css/owl.carousel.css';
import '../../assets/css/custom-style.css';
import '../../assets/css/responsive.css';
import '../../assets/css/special-classes.css';
const CounterSection = () => {
    return (
        <div className="counter-section w-100 float-left">
            <div className="counter-outer-layer">
                <div className="container">
                    <div className="counter-inner-sec">
                        <ul className="list-unstyled mb-0" data-aos="fade-up" data-aos-duration="700">
                            <li>
                                <figure>
                                    <img src={counterImg1} alt="counter-img1" />
                                </figure>
                                <div className="count d-inline-block">200k+</div>
                                <span className="d-block client-status">Patients</span>
                            </li>
                            <li>
                                <figure>
                                    <img src={counterImg2} alt="counter-img2" />
                                </figure>
                                <div className="count d-inline-block">400+</div>
                                <span className="d-block client-status">Primary Care Professionals</span>
                            </li>
                            <li>
                                <figure>
                                    <img src={counterImg3} alt="counter-img3" />
                                </figure>
                                <div className="count d-inline-block">160+</div>
                                <span className="d-block client-status">Own WorkForce</span>
                            </li>
                            <li>
                                <figure>
                                    <img src={counterImg4} alt="counter-img4" />
                                </figure>
                                <div className="count d-inline-block">23</div>
                                <span className="d-block client-status">BwD Practices</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CounterSection;
