import React from 'react';
// import ServicesSideSection from './ServicesSideSection'; // Assuming ServicesSideSection is another React component
import '../../assets/bootstrap/bootstrap.min.css'
import '../../assets/css/aos.css'
import '../../assets/css/custom.css';
import '../../assets/css/mobile.css';
import '../../assets/css/owl.carousel.css';
import '../../assets/css/custom-style.css';
import '../../assets/css/responsive.css';
import '../../assets/css/special-classes.css';
import careHomeMedicinesImg from '../../assets/images/Homeimages/claimservice.webp';
import ServiceSideSection from '../serviceSideSection';
import BannerDynammic from '../BannerD';


const ClaimService = () => {
  return (
    <>
<BannerDynammic had1={"Claims Service"}/>
<section className="singleblog-section blogpage-section">
      <div className="container">
        <div className="row">
          <div className=" col-md-12 col-sm-12 col-12">
            <div className="main-box">
              <figure className="image1" data-aos="fade-up" data-aos-duration="700">
                <img src={careHomeMedicinesImg} alt="" className="img-fluid" />
              </figure>
              <div className="content1" data-aos="fade-up" data-aos-duration="700">
              <p className="text-size-16">
              The person that gets the most benefit is our Clinical Coding and QOF lead. But in addition both Myself and the partners agree that the work you are doing is just super for us and saving us a great deal of stress and worry.   The detailed reports you share has helped us look at things differently and realise that with some minor tweaks and adjustments we can increase our income and improve coding knowledge across the teams – especially the clinical teams.  We are delighted with the work so far.  Not least as it takes away a burden from us with the assurance of it being done well, on time and with a greater understanding of individual patients care that crosses into other domains and is often missed.  I wouldn’t hesitate to endorse you wider if you ever needed it.
</p>
              </div>
            </div>
          </div>
          {/* <div className="col-lg-4 col-md-12 col-sm-12 col-12 column">

          </div> */}
        </div>
      </div>
      <ServiceSideSection />
    </section>
     </>

  );
};

export default ClaimService;
