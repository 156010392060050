// import React, { useState } from 'react';
// import { Carousel } from 'react-bootstrap';
// import starsImg from '../../assets/images/stars-img.png';
// import '../../assets/bootstrap/bootstrap.min.css'
// import '../../assets/css/aos.css'
// import '../../assets/css/custom.css';
// import '../../assets/css/mobile.css';
// import '../../assets/css/owl.carousel.css';
// import '../../assets/css/custom-style.css';
// import '../../assets/css/responsive.css';
// import '../../assets/css/special-classes.css';
// // import starsImg from '../../assets/images/stars-img.png';
// const TestimonialSection = () => {
//     const [index, setIndex] = useState(0);

//     const handleSelect = (selectedIndex) => {
//         setIndex(selectedIndex);
//     };

//     return (
//         <section className="testimonial-main-sec w-100 float-left padding-top position-relative padding-bottom">
//             <div className="container">
//                 <div className="generic-title text-center mb-0">
//                     <span className="d-block small-text" data-aos="fade-up" data-aos-duration="700">Testimonials</span>
//                     <h2 data-aos="fade-up" data-aos-duration="700">What Our Patients are Saying</h2>
//                 </div>
//                 <div className="testimonial-inner-sec" data-aos="fade-up" data-aos-duration="700">
//                     <Carousel activeIndex={index} onSelect={handleSelect}>
//                         <Carousel.Item>
//                             <div className="review-details-box">
//                                 <figure className="d-inline-block">
//                                     <img src={starsImg} alt="stars-img" />
//                                 </figure>
//                                 <p>“Ignissimos ducimus ui blandiitis praesentium voluptatum deleniti atqui corrupti quos
//                                     dolores
//                                     aet quas molestias excepturi, sint occaesatii gnissimos ducimus molestiae non <br />
//                                     recusandae itarue earum rerum tenetur a saiente delectus.”</p>
//                                 <div className="customer-status">
//                                     <h5>Jonathan Andrew</h5>
//                                     <span className="d-block">Happy Customer</span>
//                                 </div>
//                             </div>
//                         </Carousel.Item>
//                         <Carousel.Item>
//                             <div className="review-details-box">
//                                 <figure className="d-inline-block">
//                                     <img src={starsImg} alt="stars-img" />
//                                 </figure>
//                                 <p>“Ignissimos ducimus ui blandiitis praesentium voluptatum deleniti atqui corrupti quos
//                                     dolores
//                                     aet quas molestias excepturi, sint occaesatii gnissimos ducimus molestiae non <br />
//                                     recusandae itarue earum rerum tenetur a saiente delectus.”</p>
//                                 <div className="customer-status">
//                                     <h5>Alice Waters</h5>
//                                     <span className="d-block">Happy Customer</span>
//                                 </div>
//                             </div>
//                         </Carousel.Item>
//                         <Carousel.Item>
//                             <div className="review-details-box">
//                                 <figure className="d-inline-block">
//                                     <img src={starsImg} alt="stars-img" />
//                                 </figure>
//                                 <p>“Ignissimos ducimus ui blandiitis praesentium voluptatum deleniti atqui corrupti quos
//                                     dolores
//                                     aet quas molestias excepturi, sint occaesatii gnissimos ducimus molestiae non <br />
//                                     recusandae itarue earum rerum tenetur a saiente delectus.”</p>
//                                 <div className="customer-status">
//                                     <h5>Jamie Oliver</h5>
//                                     <span className="d-block">Happy Customer</span>
//                                 </div>
//                             </div>
//                         </Carousel.Item>
//                     </Carousel>
//                 </div>
//             </div>
//         </section>
//     );
// };

// export default TestimonialSection;


import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-bootstrap';
// import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/bootstrap/bootstrap.min.css'
import '../../assets/css/aos.css'
import '../../assets/css/custom.css';
import '../../assets/css/mobile.css';
import '../../assets/css/owl.carousel.css';
import '../../assets/css/custom-style.css';
import '../../assets/css/responsive.css';
import '../../assets/css/special-classes.css';
import starsImg from '../../assets/images/stars-img.png';
import thumbnail1 from '../../assets/images/Homeimages/client-img1.jpg';
import thumbnail2 from '../../assets/images/Homeimages/client-img2.jpg';
import thumbnail3 from '../../assets/images/Homeimages/client-img3.jpg';

const TestimonialSection = () => {
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };

    useEffect(() => {
        const AOS = require('aos');
        AOS.init({ duration: 700 });
    }, []);

    return (
        <section className="testimonial-main-sec w-100 float-left padding-top position-relative padding-bottom">
            <div className="container">
                <div className="generic-title text-center mb-0">
                    <span className="d-block small-text" data-aos="fade-up" data-aos-duration="700">Read Some</span>
                    <h2 data-aos="fade-up" data-aos-duration="700">Testimonials</h2>
                </div>
                <div className="testimonial-inner-sec" data-aos="fade-up" data-aos-duration="700">
                    <Carousel className='carousel slide carousel-fade' activeIndex={index} onSelect={handleSelect} indicators={false}>
                        <Carousel.Item>
                            <div className="review-details-box text-center">
                                <figure className="d-inline-block">
                                    <img src={starsImg} alt="stars-img" />
                                </figure>
                                <p>“Fantastic service and the employees genuinely want to help. So easy to get through to which is refreshing. Also, when I send an enquire, I usually get a quick response within a couple of hours! I appreciate this proactive behaviour.”</p>
                                <div className="customer-status">
                                    <h5>A.Logan </h5>
                                    <span className="d-block">A Patient</span>
                                </div>
                            </div>
                        </Carousel.Item>
                        <Carousel.Item>
                            <div className="review-details-box text-center">
                                <figure className="d-inline-block">
                                    <img src={starsImg} alt="stars-img" />
                                </figure>
                                <p>“I write to commend you and your staff for the professional, friendly manner at which they dealt with my query. After the worry of my father not taking his medication correctly, I feel that blister packs will be a great way of keeping track. Thank you for contacting the pharmacy on our behalf and arranging. Much Appreciated as always, keep up the good work.”</p>
                                <div className="customer-status">
                                    <h5>R.Catterall </h5>
                                    <span className="d-block">A Relative </span>
                                </div>
                            </div>
                        </Carousel.Item>
                        <Carousel.Item>
                            <div className="review-details-box text-center">
                                <figure className="d-inline-block">
                                    <img src={starsImg} alt="stars-img" />
                                </figure>
                                <p>“Thank you to all the staff at the prescription hub for your help, care, and support during the last few months. I was struggling to understand and juggle the ordering of my sister’s palliative care medication, and you took that worry away. I cannot Thank you enough for informing me of any changes and helping to synchronise everything, so I was not having to call in for her pain medication every week. For making a stressful situation easier at that time is something I will be forever grateful for. ”</p>
                                <div className="customer-status">
                                    <h5>M.Hodgkinson</h5>
                                    <span className="d-block">A Carer</span>
                                </div>
                            </div>
                        </Carousel.Item>
                    </Carousel>
                    <div className="carousel-indicators custom-indicators">
    <button
        className={`carousel-btn ${index === 0 ? 'active' : ''}`}
        onClick={() => handleSelect(0)}
        style={{
            cursor: 'pointer',
            width: '20px',
            height: '20px',
            margin: '5px',
            borderRadius: '50%',
            backgroundColor: `${index === 0 ? '#298d9c' : '#ccc'}`,
            border: `${index === 0 ? '2px solid #298d9c' : 'none'}`
        }}
    />
    <button
        className={`carousel-btn ${index === 1 ? 'active' : ''}`}
        onClick={() => handleSelect(1)}
        style={{
            cursor: 'pointer',
            width: '20px',
            height: '20px',
            margin: '5px',
            borderRadius: '50%',
            backgroundColor: `${index === 1 ? '#298d9c' : '#ccc'}`,
            border: `${index === 1 ? '2px solid #298d9c' : 'none'}`
        }}
    />
    <button
        className={`carousel-btn ${index === 2 ? 'active' : ''}`}
        onClick={() => handleSelect(2)}
        style={{
            cursor: 'pointer',
            width: '20px',
            height: '20px',
            margin: '5px',
            borderRadius: '50%',
            backgroundColor: `${index === 2 ? '#298d9c' : '#ccc'}`,
            border: `${index === 2 ? '2px solid #298d9c' : 'none'}`
        }}
    />
</div>

                </div>
            </div>
        </section>
    );
};

export default TestimonialSection;
