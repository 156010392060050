import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Header from './Components/Header';
import FooterSection from './Components/Footer';
import Home from './pages/Home';
import LpcFerderatee from './pages/LpcFerderate';
import CaseStudies from './pages/CaseStudies';
import AboutUs from './pages/about';
import Board from './pages/board';
import Team from './pages/team';
import AllServices from './pages/allServices';
import EnhancedAccess from './Components/enhancedAccess/iindex';
import Longcovid from './Components/Longcovidservice';
import CarehomeMedicines from './Components/carehomemedicines';
import Carehomevir from './Components/virtualcarehome';
import VaccinationService from './Components/vaccinationservice';
import Workflowhub from './Components/workflow';
import Prescriptionhub from './Components/prescription';
import ClaimService from './Components/claimService';
import Secretarial from './Components/secretarial';
import PracticeSupport from './Components/practiceSupport';
import Professionaltraining from './Components/professionaltraining';
import NotFound from './pages/notfound';
import Arrs from './Components/arrs';
import Peoplemanagement from './Components/peoplemange';
import Loader from './Components/Loader';
import ReceptionSupport from './Components/receptionSupport';
import CervicalScreen from './Components/cervicalscreen';
import CoreNavigatiion from './Components/corenavigation';
import WorkForcedeploy from './Components/workforcedeploy';
import Blogs from './pages/blogs';

const App = () => {
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return <Loader />;
  }
  const hideHeaderAndFooter = location.pathname === '/404';

  return (
    <>
      {!hideHeaderAndFooter && <Header />}

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/lpcfederate" element={<LpcFerderatee />} />
        <Route path="/successstories" element={<CaseStudies />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/board" element={<Board />} />
        <Route path="/team" element={<Team />} />
        <Route path="/services" element={<AllServices />} />
        <Route path="/enhancedaccess" element={<EnhancedAccess />} />
        <Route path="/longcovid" element={<Longcovid />} />
        <Route path="/homecaremedicine" element={<CarehomeMedicines />} />
        <Route path="/virtualcarehome" element={<Carehomevir />} />
        <Route path="/vaccination" element={<VaccinationService />} />
        <Route path="/workflow" element={<Workflowhub />} />
        <Route path="/prescriptionhub" element={<Prescriptionhub />} />
        <Route path="/claimservice" element={<ClaimService />} />
        <Route path="/secretarialhub" element={<Secretarial />} />
        <Route path="/practicesupport" element={<PracticeSupport />} />
        <Route path="/professionaltraining" element={<Professionaltraining />} />
        <Route path="/peoplemanage" element={<Peoplemanagement />} />
        <Route path="/receptionsupport" element={<ReceptionSupport />} />
        <Route path="/cervicalscreening" element={<CervicalScreen/>} />
        <Route path="/corenavigation" element={<CoreNavigatiion/>} />
        <Route path="/workforce" element={<WorkForcedeploy/>} />
        <Route path="/blogs" element={<Blogs/>} />



        
        <Route path="/arrs" element={<Arrs />} />

        <Route path="*" element={<NotFound />} />
      </Routes>

      {!hideHeaderAndFooter && <FooterSection />}
    </>
  );
};

export default App;
