import React from 'react';
// import ServicesSideSection from './ServicesSideSection'; // Assuming ServicesSideSection is another React component
import '../../assets/bootstrap/bootstrap.min.css'
import '../../assets/css/aos.css'
import '../../assets/css/custom.css';
import '../../assets/css/mobile.css';
import '../../assets/css/owl.carousel.css';
import '../../assets/css/custom-style.css';
import '../../assets/css/responsive.css';
import '../../assets/css/special-classes.css';
import careHomeMedicinesImg from '../../assets/images/Homeimages/carehomemedicines.webp';
import ServiceSideSection from '../serviceSideSection';
import BannerDynammic from '../BannerD';


const Prescriptionhub = () => {
  return (
    <>
<BannerDynammic had1={"Prescription Hub"}/>
<section className="singleblog-section blogpage-section">
      <div className="container">
        <div className="row">
          <div className=" col-md-12 col-sm-12 col-12">
            <div className="main-box">
              <figure className="image1" data-aos="fade-up" data-aos-duration="700">
                <img src={careHomeMedicinesImg} alt="" className="img-fluid" />
              </figure>
              <div className="content1" data-aos="fade-up" data-aos-duration="700">
                <h4 className='font-bold'>Prescription Hub from Patients who have utilised the service</h4>
              <p className="text-size-16">
  TFantastic service and the employees genuinely want to help. So easy to get through to which is refreshing. Also, when I send an enquire, I usually get a quick response within a couple of hours! I appreciate this proactive behaviour. A.Logan  <br />
  <br /> I write to commend you and your staff for the professional, friendly manner at which they dealt with my query. After the worry of my father not taking his medication correctly, I feel that blister packs will be a great way of keeping track. Thank you for contacting the pharmacy on our behalf and arranging. Much Appreciated as always, keep up the good work. R.Catterall  <br />
  <br /> Thank you to all the staff at the prescription hub for your help, care, and support during the last few months. I was struggling to understand and juggle the ordering of my sister’s palliative care medication, and you took that worry away. I cannot Thank you enough for informing me of any changes and helping to synchronise everything, so I was not having to call in for her pain medication every week. For making a stressful situation easier at that time is something I will be forever grateful for. M.Hodgkinson
</p>

<h4 className='font-bold'>Prescription Hub from practice</h4>
<p className="text-size-16">
We sincerely appreciate the excellent work that your team is doing processing prescriptions. <br />
  <br /> The huge demand on our telephones currently sadly sometimes overshadows the good work of the prescription hub as we don’t see the levels you are taking from what would potentially be an even larger load.  We appreciate your forward thinking of new ways of working of how your team can further support practices. <br />
  <br /> T 
Communication with yourself re- patients concerns/complaints or anything needing some clarity is always approachable and responsive – thank you.
<br/><br/>
Your team have been a fantastic help, was lovely to speak with 
</p>

              </div>
            </div>
          </div>

        </div>
      </div>
      <ServiceSideSection />

    </section>
     </>

  );
};

export default Prescriptionhub;
