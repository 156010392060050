import React, { useState } from 'react';
import processLeftImg from '../../assets/images/Homeimages/process-left-img.png';
import '../../assets/bootstrap/bootstrap.min.css';
import '../../assets/css/aos.css';
import '../../assets/css/custom.css';
import '../../assets/css/mobile.css';
import '../../assets/css/owl.carousel.css';
import '../../assets/css/custom-style.css';
import '../../assets/css/responsive.css';
import '../../assets/css/special-classes.css';
import './formcont.css'; // Import the new CSS file
import { MdCall } from "react-icons/md";
import { IoMdMailUnread } from "react-icons/io";
import { FaAddressBook } from "react-icons/fa";
const ProcessContSection = () => {
    const [formData, setFormData] = useState({
        fname: '',
        lname: '',
        email: '',
        phone: '',
        subject: ''
    });
    const [isChecked, setIsChecked] = useState(true);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleCheckboxChange = (e) => {
        setIsChecked(e.target.checked);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission logic here
        console.log('Form data:', formData);
    };

    return (
        <section id='contact' className="process-main-section w-100 float-left padding-top padding-bottom contact-main-sec">
            <div className="container">
                <div className="process-inner-con">
                    <div className="process-left-sec" data-aos="fade-up" data-aos-duration="700">
                        <figure className="mb-0">
                            <img src={processLeftImg} alt="process-left-img" />
                        </figure>
                    </div>
                    <div className="process-right-sec mt-3">
  <div className="generic-title">
  <h2 data-aos="fade-up" data-aos-duration="700">Send us a Message</h2>
  </div>
  <ul className="mb-0 list-unstyled">
    <li
      data-aos="fade-up"
      data-aos-duration={700}
      className="aos-init aos-animate"
    >
      <div className="process-img-sec">
        <figure>
        <MdCall size={30} color='#156c5f'/>
        </figure>
      </div>
      <div className="process-text-con">
        <h4>Telephone:</h4>
        <p>01254 961018</p>
      </div>
    </li>
    <li
      data-aos="fade-up"
      data-aos-duration={700}
      className="aos-init aos-animate"
    >
      <div className="process-img-sec">
        <figure>
          <IoMdMailUnread size={27} color='#156c5f'/>
        </figure>
      </div>
      <div className="process-text-con">
        <h4>Email:</h4>
        <p>localprimarycare@nhs.net</p>
      </div>
    </li>
    <li
      data-aos="fade-up"
      data-aos-duration={700}
      className="aos-init aos-animate"
    >
      <div className="process-img-sec">
        <figure>
        <FaAddressBook  size={27} color='#156c5f'/>
        </figure>
      </div>
      <div className="process-text-con">
        <h4>Address:</h4>
        <p>
Blackburn Enterprise Centre, 
Furthergate. 
Blackburn, 
BB1 3HQ
        </p>
      </div>
    </li>
  </ul>

</div>

                </div>
            </div>
        </section>
    );
};

export default ProcessContSection;
