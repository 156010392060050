import React from 'react';
import { FaFacebookF, FaTwitter, FaInstagram, FaAngleRight, FaPhoneAlt, FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa';
import '../../assets/bootstrap/bootstrap.min.css'
// import '../../assets/css/aos.css'
import '../../assets/css/aos.css';
import '../../assets/css/custom.css';
import '../../assets/css/mobile.css';
import '../../assets/css/owl.carousel.css';
import '../../assets/css/custom-style.css';
import '../../assets/css/responsive.css';
import '../../assets/css/special-classes.css';
import logo from '../../assets/images/LPClogo.png';
import { Link } from 'react-router-dom';


const FooterSection = () => {
    const scrollToSection = (id) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    return (
        <>
            <section className="footer-main-section w-100 float-left position-relative">
                <div className="container">
                    <div className="">
                        {/* <h4>Subscribe To Our <br /> Newsletter</h4>
                        <div className="form-sec">
                            <input type="email" name="email" id="mail" placeholder="Your Email Address:" />
                            <button type="submit" id="submit" className="sign-up-btn">Sign Up Now</button>
                        </div> */}
                    </div>
                    <div className="footer-inner-sec p-5">
                        <div className="footer-boxes">
                            <div className="footer-logo-con">
                                <a href="index">
                                    <figure>
                                        <img src={logo} width={200} alt="logo" />
                                    </figure>
                                </a>
                                {/* <p>Deleniti aeue corrupti quos dolores quas tias excepturi sint occaecati rupiditate non similique sunt incidunt...</p> */}
                                <div className="social-links" style={{marginTop: "-30px"}}>
                                    <ul className="list-unstyled mb-0 d-flex">
                                    <li><a class="facebook" href="https://www.facebook.com/BlackburnLocalPrimaryCare" target="_blank" ><i class="fab fa-facebook-f"></i></a>
                    </li>
                    <li><a class="twitter"
                            href="https://twitter.com/lpcfederation?lang=en" target="_blank"><i
                                class="fab fa-twitter"></i></a></li>
                    <li class="mb-0"><a class="instagram"
                            href="https://www.instagram.com/lpcfederation/" target="_blank"><i
                                class="fab fa-instagram"></i></a>
                    </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="footer-text-boxes">
                            <h4>About Us</h4>
                            <ul className="mb-0 ms-0 list-unstyled">
                                <li className="position-relative"><Link to={"/about"}><FaAngleRight /> About us</Link></li>
                                <li className="position-relative"><Link to={"/successstories"}><FaAngleRight /> Success Stories</Link></li>
                                <li className="position-relative"><Link to={"/blogs"}><FaAngleRight />Blogs</Link></li>
                                <li className="position-relative mb-0"><Link to={"/#contact"} onClick={() => scrollToSection('contact')}><FaAngleRight /> Contact us</Link></li>
                            </ul>
                        </div>
                        <div className="footer-text-boxes">
                            <h4>Our Services</h4>
                            <ul className="mb-0 list-unstyled">
                                <li className="position-relative"><Link to="/claimservice"><FaAngleRight /> Claims Service</Link></li>
                                <li className="position-relative"><Link to="/secretarialhub"><FaAngleRight /> Secretarial Hub</Link></li>
                                <li className="position-relative"><Link to={"/workflow"}><FaAngleRight /> Workflow Hub</Link></li>
                                <li className="position-relative"><Link to={"/prescriptionhub"}><FaAngleRight /> Prescription Hub</Link></li>
                                <li className="position-relative mb-0"><Link to="/longcovid"><FaAngleRight /> Long Covid Service</Link></li>
                            </ul>
                        </div>
                        <div className="footer-text-boxes">
                            <h4>Contact Info</h4>
                            <ul className="mb-0 list-unstyled">
                                <li className="position-relative"><a href="tel:01254961018"><FaPhoneAlt /> 012 549 61018</a></li>
                                <li className="position-relative"><a href="mailto:localprimarycare@nhs.net"><FaEnvelope /> localprimarycare@nhs.net</a></li>
                                <li className="position-relative mb-0"><FaMapMarkerAlt /> Blackburn Enterprise Centre, Furthergate. Blackburn, BB1 3HQ</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <div className="copy-rihgt-sec w-100 float-left text-center">
                <p className="mb-0">© Copyright 2024 Local Primary Care. All Rights Reserved.</p>
            </div>
         
            <a id="button"></a>
        </>
    );
};

export default FooterSection;
