import React from 'react';
// import ServicesSideSection from './ServicesSideSection'; // Assuming ServicesSideSection is another React component
import '../../assets/bootstrap/bootstrap.min.css'
import '../../assets/css/aos.css'
import '../../assets/css/custom.css';
import '../../assets/css/mobile.css';
import '../../assets/css/owl.carousel.css';
import '../../assets/css/custom-style.css';
import '../../assets/css/responsive.css';
import '../../assets/css/special-classes.css';
import careHomeMedicinesImg from '../../assets/images/Homeimages/practicesupport.webp';
import ServiceSideSection from '../serviceSideSection';
import BannerDynammic from '../BannerD';


const PracticeSupport = () => {
  return (
    <>
<BannerDynammic had1={"Practice Support Service"}/>
<section className="singleblog-section blogpage-section">
      <div className="container">
        <div className="row">
          <div className=" col-md-12 col-sm-12 col-12">
            <div className="main-box">
              <figure className="image1" data-aos="fade-up" data-aos-duration="700">
                <img src={careHomeMedicinesImg} alt="" className="img-fluid" />
              </figure>
              <div className="content1" data-aos="fade-up" data-aos-duration="700">
              <p className="text-size-16">
  Our Practice Support Team is the backbone of efficiency and reliability for
  your GP practice. With years of experience in working in General Practice, we
  understand the intricate needs of your establishment, and our dedicated team
  is here to ensure seamless operations in every aspect of general practice
  life. <br />
  <br />
  The Practice Support Team is comprised of highly trained individuals
  proficient in receptionist duties, administrative tasks, care navigation and
  EMIS web. From managing appointments to streamlining paperwork, our team is
  equipped to handle it all with precision and proficiency. <br />
  <br />
  Whether you need coverage for planned absences such as holidays or last-minute
  support for unexpected staff shortages, our service is here to provide timely
  assistance. With the option to book in advance or request immediate cover, you
  can rely on us to keep your practice running smoothly, ensuring uninterrupted
  care for your patients.
</p>





              </div>
            </div>
          </div>

        </div>
      </div>
      <ServiceSideSection />

    </section>
     </>

  );
};

export default PracticeSupport;
