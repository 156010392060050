import React from 'react';
// import ServicesSideSection from './ServicesSideSection'; // Assuming ServicesSideSection is another React component
import '../../assets/bootstrap/bootstrap.min.css'
import '../../assets/css/aos.css'
import '../../assets/css/custom.css';
import '../../assets/css/mobile.css';
import '../../assets/css/owl.carousel.css';
import '../../assets/css/custom-style.css';
import '../../assets/css/responsive.css';
import '../../assets/css/special-classes.css';
import careHomeMedicinesImg from '../../assets/images/Homeimages/reception.webp';
import ServiceSideSection from '../serviceSideSection';
import BannerDynammic from '../BannerD';


const ReceptionSupport = () => {
  return (
    <>
<BannerDynammic had1={"Reception Support Service"}/>
<section className="singleblog-section blogpage-section">
      <div className="container">
        <div className="row">
          <div className=" col-md-12 col-sm-12 col-12">
            <div className="main-box">
              <figure className="image1" data-aos="fade-up" data-aos-duration="700">
                <img src={careHomeMedicinesImg} alt="" className="img-fluid" />
              </figure>
              <div className="content1" data-aos="fade-up" data-aos-duration="700">
  
  <p className="text-size-16">
  
We are very pleased with the support we have received.  They are a lovely bunch of people who have adapted to our different way of working very quickly.

  </p>
  <p />






              </div>
            </div>
          </div>
        </div>
      </div>
      <ServiceSideSection />

    </section>
     </>

  );
};

export default ReceptionSupport;
