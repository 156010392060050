import React from 'react';
// import ServicesSideSection from './ServicesSideSection'; // Assuming ServicesSideSection is another React component
import '../../assets/bootstrap/bootstrap.min.css'
import '../../assets/css/aos.css'
import '../../assets/css/custom.css';
import '../../assets/css/mobile.css';
import '../../assets/css/owl.carousel.css';
import '../../assets/css/custom-style.css';
import '../../assets/css/responsive.css';
import '../../assets/css/special-classes.css';
import careHomeMedicinesImg from '../../assets/images/Homeimages/workflow.webp';
import ServiceSideSection from '../serviceSideSection';
import BannerDynammic from '../BannerD';


const Workflowhub = () => {
  return (
    <>
<BannerDynammic had1={"Workflow hub"}/>
<section className="singleblog-section blogpage-section">
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-sm-12 col-12">
            <div className="main-box">
              <figure className="image1" data-aos="fade-up" data-aos-duration="700">
                <img src={careHomeMedicinesImg} alt="" className="img-fluid" />
              </figure>
              <div className="content1" data-aos="fade-up" data-aos-duration="700">
              <p className="text-size-16">
              The staff and support are second to none at the workflow hub. They are polished, proficient, accessible, professional, and always there to offer advice and support <br />
  <br /> It is very reassuring knowing that if we are struggling with staffing issues, the workflow team are on hand to support with our documents. They work with our practice and set up clear guidance for all staff to follow. <br/><br/>
  We have never had any issues with the work produced and will continue to use this excellent service.
  <br/><br/>
  The document workflow hub has been a God send!  Not just to GPs but also to our entire workforce.  The time we have freed up for all staff to concentrate on other aspects of work has been vast.  We also feel very assured our coding is 100% accurate and actions from letters are dealt with in a much more timely manner than they were before.   Any issues we identify are dealt with quickly by LPC and having a small dedicated team at LPC that works for our practice we have found really works for us as they serve as a point of contact and we build relationships with them
  <br/><br/>
  We would like to say thank you for brilliant communication and service regarding the workflow hub - it has massively helped with our day to day workload, the letters are very efficiently actioned and it has made the working day much lighter for us all!
</p>


              </div>
            </div>
          </div>

        </div>
      </div>
      <ServiceSideSection />

    </section>
     </>

  );
};

export default Workflowhub;
