import React from 'react'
import aboutUsImage from '../assets/images/Homeimages/about-us-about-img.jpg';
import '../assets/bootstrap/bootstrap.min.css'
import '../assets/css/aos.css'
import '../assets/css/custom.css';
import '../assets/css/mobile.css';
import '../assets/css/owl.carousel.css';
import '../assets/css/custom-style.css';
import '../assets/css/responsive.css';
import '../assets/css/special-classes.css';
import { Link } from 'react-router-dom';
import processLeftImg from '../assets/images/Homeimages/1.png';
import processLeftImg1 from '../assets/images/Homeimages/2.png';


const AboutUs= () => {
  return (
    <>
     {/* BANNER SECTION START */}
     <section className="sub-banner-section w-100 float-left d-flex align-items-center">
                <div className="container">
                    <div className="sub-banner-inner-con text-center">
                        <h1 data-aos="fade-up" data-aos-duration="700">About us</h1>
                        {/* <p data-aos="fade-up" data-aos-duration="700">Roidunt eget semper nec ruam sed hendrerit morbi aeu
                            feliseao augue <br>
                            pellentesue veniam morbi acer.</p> */}
                        <nav aria-label="breadcrumb" data-aos="fade-up" data-aos-duration="700">
                            <ol className="breadcrumb d-inline-block mb-0">
                                <li className="breadcrumb-item d-inline-block"><Link to={"/"}>Home</Link></li>
                                <li className="breadcrumb-item active text-white d-inline-block" aria-current="page">About</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </section>
            {/* BANNER SECTION END */}

            {/* ABOUT SECTION START */}
            <section className="about-main-section about-us-about padding-top padding-bottom w-100 float-left position-relative">
                <div className="container position-relative">
                    <div className="about-inner-con">
                        <div className="about-img-con">
                            <figure className="mb-0" data-aos="fade-up" data-aos-duration="700">
                                <img src={aboutUsImage} alt="about-us-about-img" />
                            </figure>
                        </div>
                        <div className="about-txt-con">
                            <div className="generic-title" data-aos="fade-up" data-aos-duration="700">
                                <span className="small-text">About Us</span>
                                <h2 className="mb-0">Local Primary Care</h2>
                            </div>
                            <p data-aos="fade-up" data-aos-duration="700">Local Primary Care was established in 2014 as the Federation for all General Practices in Blackburn with Darwen. Local Primary Care enables practices to provides excellent services by supporting both the practices and their patients.</p>
                            {/* <p data-aos="fade-up" data-aos-duration="700">Wiusmod tempor incididunt ut labore et dolore magna
                                aliqua zut enim ad minim.</p> */}
                            {/* <ul className="list-unstyled" data-aos="fade-up" data-aos-duration="700">
                                <li>Psychodynamic Therapy</li>
                                <li>Free Consultants</li>
                                <li>Couple Problem Therapy</li>
                                <li>Metal Satisfications</li>
                            </ul>
                            <div className="generic-btn" data-aos="fade-up" data-aos-duration="700">
                                <a href="services.html">Get Started</a>
                            </div> */}

                            <span className="small-text">Our Focus</span>
                            <p data-aos="fade-up" data-aos-duration="700">Local Primary Care works with member practices to provide excellent services to patients. Supporting practices to develop their workforce and leadership.</p>

                            <span className="small-text">Our Values</span>
                            <p data-aos="fade-up" data-aos-duration="700">In order to achieve the best care outcomes for patients, Local Primary Care believes we must all work towards our core values.</p>

                        </div>
                    </div>
                    {/* <div className="about-special-txt" data-aos="fade-up" data-aos-duration="700">
                        <figure>
                            <img src="assets/images/about-quote-img.png" alt="about-quote-img" />
                        </figure>
                        <h4>Ignissimos ducimus aui
                            aet quas molestias exceuri
                            recusandae itarue.</h4>
                    </div> */}
                </div>
            </section>
            {/* ABOUT SECTION END */}
            <section id='contact' className="process-main-section w-100 float-left padding-bottom contact-main-sec">
            <div className="container">
            <div className="generic-title text-center">
              <h2 data-aos="fade-up" data-aos-duration="700">Our Values</h2>
          </div>
                <div className="process-inner-con">
                    <div className="process-left-sec" data-aos="fade-up" data-aos-duration="700">
                        <figure className="mb-0">
                            <img src={processLeftImg} alt="process-left-img" />
                        </figure>
                    </div>
                    <div className="process-right-sec mt-5">
  <div className="generic-title">
  <h4 data-aos="fade-up" data-aos-duration="700">At Local Primary Care, we are committed to achieving the best care outcomes for our patients by upholding our core values:</h4>
  </div>
  <ul className="mb-0 list-unstyled">
    <li
      data-aos="fade-up"
      data-aos-duration={700}
      className="aos-init aos-animate"
    >
     
      <div className="process-text-con">
      <b>Caring:</b> We prioritise compassion and empathy in every patient interaction.
      </div>
    </li>
  
    <li
      data-aos="fade-up"
      data-aos-duration={700}
      className="aos-init aos-animate"
    >

      <div className="process-text-con">
 
        <p>
        <b>Excellence:</b> We strive for the highest standards in all aspects of healthcare delivery.
        </p>
      </div>
    </li>

    <li
      data-aos="fade-up"
      data-aos-duration={700}
      className="aos-init aos-animate"
    >

      <div className="process-text-con">
 
        <p>
        <b>Ethical:</b> We adhere to the highest moral principles in all our practices and decisions.
        </p>
      </div>
    </li>

    <li
      data-aos="fade-up"
      data-aos-duration={700}
      className="aos-init aos-animate"
    >

      <div className="process-text-con">
  
        <p>
        <b>Transparency:</b> We maintain open, honest communication with patients and stakeholders.
        </p>
      </div>
    </li>

    <li
      data-aos="fade-up"
      data-aos-duration={700}
      className="aos-init aos-animate"
    >

      <div className="process-text-con">
 
        <p>
        <b>Trust:</b> We build and nurture trust through reliability and integrity in all we do.
        </p>
      </div>
    </li>
  </ul>

</div>

                </div>
            </div>
        </section>
            {/* <section className="team-main-section w-100 float-left padding-topp padding-bottomm">
            <div className="container">
    <div className="generic-title text-center">
        <h2 className="mb-0"></h2>
    </div>
    <p className='text-center'></p>
    <ul style={{ listStyleType: 'none', paddingLeft: '0' }} className='text-center'>
        <li style={{ marginBottom: '10px' }}>
            
        </li>
        <li style={{ marginBottom: '10px' }}>
            
        </li>
        <li style={{ marginBottom: '10px' }}>
           
        </li>
        <li style={{ marginBottom: '10px' }}>
            
        </li>
        <li style={{ marginBottom: '10px' }}>
         
        </li>
    </ul>
</div>

            </section> */}

<section id='contact' className="process-main-section w-100 float-left padding-bottom contact-main-sec">
            <div className="container">
            <div className="generic-title text-center">
              <h2 data-aos="fade-up" data-aos-duration="700">Why Choose Us</h2>
          </div>
                <div className="process-inner-con">
             
                    <div className="process-right-sec mt-4">
  <div className="generic-title">
  <h4 data-aos="fade-up" data-aos-duration="700"> LPC works together to achieve core values. We try to maximise all our experience, accumulated potential and knowledge of modern medicine to achieve excellent primary care services to ensure sustainability.</h4>
  </div>
  <ul className="mb-0 list-unstyled">
    <li
      data-aos="fade-up"
      data-aos-duration={700}
      className="aos-init aos-animate"
    >
     
      <div className="process-text-con">
      <b>Professional Expertise:</b> Our federation comprises a highly skilled and experienced clinical and non-clinical workforce committed to ongoing local primary care development.
      </div>
    </li>
  
    <li
      data-aos="fade-up"
      data-aos-duration={700}
      className="aos-init aos-animate"
    >

      <div className="process-text-con">
 
        <p>
        <b> Innovation and Technology: </b> We harness the power of modernised healthcare technologies to enhance patient care and streamline practice management. From bespoke digital health tools to cutting-edge management methods, we value innovation.
        </p>
      </div>
    </li>

    <li
      data-aos="fade-up"
      data-aos-duration={700}
      className="aos-init aos-animate"
    >

      <div className="process-text-con">
 
        <p>
        <b> Collaborative Network: </b>By fostering a culture of collaboration among our member practices, we facilitate sharing knowledge and expertise. This collective approach enhances the quality of care and drives continuous improvement across our network.
        </p>
      </div>
    </li>

    <li
      data-aos="fade-up"
      data-aos-duration={700}
      className="aos-init aos-animate"
    >

      <div className="process-text-con">
  
        <p>
        <b>Comprehensive Support:</b>Our federation provides extensive support to our partner, including access to resources, training, and practice management assistance.
        </p>
      </div>
    </li>

    {/* <li
      data-aos="fade-up"
      data-aos-duration={700}
      className="aos-init aos-animate"
    >

      <div className="process-text-con">
 
        <p>
        <b>Trust:</b> We build and nurture trust through reliability and integrity in all we do.
        </p>
      </div>
    </li> */}
  </ul>

</div>
<div className="process-left-sec" data-aos="fade-up" data-aos-duration="700">
                        <figure className="mb-0">
                            <img src={processLeftImg1} alt="process-left-img" />
                        </figure>
                    </div>
                </div>
            </div>
        </section>
           
            {/* WHY CHOOSE US END */}
    </>
  )
}

export default AboutUs