import React from 'react';
// import ServicesSideSection from './ServicesSideSection'; // Assuming ServicesSideSection is another React component
import '../../assets/bootstrap/bootstrap.min.css'
import '../../assets/css/aos.css'
import '../../assets/css/custom.css';
import '../../assets/css/mobile.css';
import '../../assets/css/owl.carousel.css';
import '../../assets/css/custom-style.css';
import '../../assets/css/responsive.css';
import '../../assets/css/special-classes.css';
import careHomeMedicinesImg from '../../assets/images/Homeimages/carehome.webp';
import ServiceSideSection from '../serviceSideSection';
import BannerDynammic from '../BannerD';


const Carehomevir = () => {
  return (
    <>
<BannerDynammic had1={"Care Home Virtual Ward Rounds"}/>
<section className="singleblog-section blogpage-section">
      <div className="container">
        <div className="row">
          <div className=" col-md-12 col-sm-12 col-12">
            <div className="main-box">
              <figure className="image1" data-aos="fade-up" data-aos-duration="700">
                <img src={careHomeMedicinesImg} alt="" className="img-fluid" />
              </figure>
              <div className="content1" data-aos="fade-up" data-aos-duration="700">
              <p className="text-size-16">
  Experience proactive and personalised care for residents with our Care Home
  Virtual Ward Rounds. <br />
  <br /> Through weekly virtual sessions conducted via secure platforms like
  Microsoft Teams, LPC brings together a skilled team including GPs, Advanced
  Nurse Practitioners, Physician Associates, Clinical Pharmacists, and
  Specialist Care Home Nurses (where available). Our goal is to optimize
  resident health by assessing and addressing concerns promptly. <br />
  <br /> We welcome relevant members of the wider multidisciplinary team and
  encourage resident's families/carers to participate. To ensure smooth
  coordination, our dedicated Care Coordinators manage appointments and serve as
  the primary contact for the ward rounds, ensuring seamless communication and
  efficient processes.{" "}
</p>

              </div>
            </div>
          </div>

        </div>
      </div>
      <ServiceSideSection />

    </section>
     </>

  );
};

export default Carehomevir;
