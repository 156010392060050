import React from 'react';
// import ServicesSideSection from './ServicesSideSection'; // Assuming ServicesSideSection is another React component
import '../../assets/bootstrap/bootstrap.min.css'
import '../../assets/css/aos.css'
import '../../assets/css/custom.css';
import '../../assets/css/mobile.css';
import '../../assets/css/owl.carousel.css';
import '../../assets/css/custom-style.css';
import '../../assets/css/responsive.css';
import '../../assets/css/special-classes.css';
import careHomeMedicinesImg from '../../assets/images/Homeimages/corenavi.webp';
import ServiceSideSection from '../serviceSideSection';
import BannerDynammic from '../BannerD';


const CoreNavigatiion = () => {
  return (
    <>
<BannerDynammic had1={"Core Navigation Training"}/>
<section className="singleblog-section blogpage-section">
      <div className="container">
        <div className="row">
          <div className=" col-md-12 col-sm-12 col-12">
            <div className="main-box">
              <figure className="image1" data-aos="fade-up" data-aos-duration="700">
                <img src={careHomeMedicinesImg} alt="" className="img-fluid" />
              </figure>
              <div className="content1" data-aos="fade-up" data-aos-duration="700">
              <p className="text-size-16">
              Enhance the patient journey by equipping your staff with the skills and knowledge necessary to effectively navigate the healthcare system. Our Care Navigation Training is designed to support healthcare professionals in directing patients to the most appropriate services, reducing the burden on GPs, and improving overall care efficiency.
              <br />   <br /> Our training is conducted by experienced healthcare professionals and is tailored to meet the specific needs of your organization. Whether delivered in-person or virtually, participants will leave with practical skills and confidence to improve care navigation within your team.
</p>

              </div>
            </div>
          </div>

        </div>
      </div>
      <ServiceSideSection />

    </section>
     </>

  );
};

export default CoreNavigatiion;
