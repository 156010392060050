import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";


const serviceItems = [
  "ST GEORGES SURGERY",
  "WILIAM HOPWOD STREET SURGERY",
  "STEPPING STONE PRACTICE",
  "HOLLINS GROVE SURGERY",
  "SPRING FENISCO HEALTH LINK",
  "BROWNHILL SURGERY",
  "LITTLE HARWOOD HEALTH CENTRE",
  "PRIMROSE BANK MEDICAL CENTER",
  "ROE LEE SURGERY",
  "EWOOD MEDICAL CENTRE",
  "LIMEFIELD SURGERY",
  "OAKENHURST SURGERY",
  "REDLAM SURGERY",
  "THE FAMILY PRACTICE",
  "WITTON MEDICAL CENTRE",
  "DARWEN HEALTH CENTRE",
  "DARWEN HEALTH LINK",
  "SHIFA SURGERY BANGOR STREET",
  "OLIVE MEDICAL CENTRE",
];

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1024 },
    items: 3,
  },
  desktop: {
    breakpoint: { max: 1024, min: 768 },
    items: 2,
  },
  tablet: {
    breakpoint: { max: 768, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};


const ServiceCarousel = () => {
  return (
 <div>
        <Carousel 
          responsive={responsive}
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={3000}
        //   customButtonGroup={<CustomControls />}
          arrows={false}
          
        >
          {serviceItems.map((item, index) => (
            <div className="service-carousel-item">
            <div key={index} className="service-type-boxxx text-center p-4" data-aos="fade-up" data-aos-duration="700">
              <h4>{item}</h4>
            </div>
            </div>
          ))}
        </Carousel>
        </div>
  );
};

export default ServiceCarousel;
