import React from 'react'
import teamImg1 from '../assets/images/Homeimages/team-img1.jpeg';
import teamImg2 from '../assets/images/Homeimages/team-img2.jpeg';
import teamImg3 from '../assets/images/Homeimages/team-img3.jpeg';
import teamImg4 from '../assets/images/Homeimages/team-img4.jpeg';
import '../assets/bootstrap/bootstrap.min.css'
import '../assets/css/aos.css'
import '../assets/css/custom.css';
import '../assets/css/mobile.css';
import '../assets/css/owl.carousel.css';
import '../assets/css/custom-style.css';
import '../assets/css/responsive.css';
import '../assets/css/special-classes.css';
import ListServiceSection from '../Components/servicesList';


import processLeftImg from '../assets/images/process-left-img.jpg';
import processIcon1 from '../assets/images/process-icon1.png';
import processIcon2 from '../assets/images/process-icon2.png';
import processIcon3 from '../assets/images/process-icon3.png';
import TestimonialSection from '../Components/testiminials';
import BlogSection from '../Components/BlogsSlider';
import { Link } from 'react-router-dom';
const AllServices = () => {
  return (
    <>
  <section className="service-banner sub-banner-section w-100 float-left d-flex align-items-center">
    <div className="container">
      <div className="sub-banner-inner-con text-center">
        <h1 data-aos="fade-up" data-aos-duration={700}>
          Services
        </h1>
        <p data-aos="fade-up" data-aos-duration={700}>
          Noidunt eget semper nec ruam sed hendrerit morbi aeu feliseao augue{" "}
          <br />
          pellentesue veniam morbi acer.
        </p>
        <nav aria-label="breadcrumb" data-aos="fade-up" data-aos-duration={700}>
          <ol className="breadcrumb d-inline-block mb-0">
            <li className="breadcrumb-item d-inline-block">
              <Link to={"/"}>Home</Link>
            </li>
            <li
              className="breadcrumb-item active text-white d-inline-block"
              aria-current="page"
            >
              Services
            </li>
          </ol>
        </nav>
      </div>
    </div>
  </section>
  {/* BANNER SECTION END */}

<ListServiceSection/>

{/* <section className="process-main-section w-100 float-left padding-top padding-bottom light-bg">
      <div className="container">
        <div className="process-inner-con">
          <div className="process-left-sec" data-aos="fade-up" data-aos-duration="700">
            <figure className="mb-0">
              <img src={processLeftImg} alt="process-left-img" />
            </figure>
          </div>
          <div className="process-right-sec">
            <div className="generic-title">
              <span className="small-text" data-aos="fade-up" data-aos-duration="700">How it Works</span>
              <h2 data-aos="fade-up" data-aos-duration="700">Professional Psychology Therapy You Can Choose</h2>
            </div>
            <ul className="mb-0 list-unstyled" data-aos="fade-up" data-aos-duration="700">
              <li>
                <div className="process-img-sec">
                  <figure>
                    <img src={processIcon1} alt="process-icon1" />
                  </figure>
                </div>
                <div className="process-text-con">
                  <h4>Talk to Us First</h4>
                  <p>Nuis aute irure dolor reprehenderit in volutae velit esse fugiat.</p>
                </div>
              </li>
              <li>
                <div className="process-img-sec">
                  <figure>
                    <img src={processIcon2} alt="process-icon2" />
                  </figure>
                </div>
                <div className="process-text-con">
                  <h4>Book an Appointment</h4>
                  <p>Blandit fauce bus perce viverra sem rutrum aeu vulputate came.</p>
                </div>
              </li>
              <li>
                <div className="process-img-sec">
                  <figure>
                    <img src={processIcon3} alt="process-icon3" />
                  </figure>
                </div>
                <div className="process-text-con">
                  <h4>Come Sit With Us</h4>
                  <p>Maiores alias consequatur aut perferendis dolor reus asperiores repellat.</p>
                </div>
              </li>
            </ul>
            <div className="generic-btn">
              <Link to={"/#contact"}>Book Appointment</Link>
            </div>
          </div>
        </div>
      </div>
    </section> */}

    <TestimonialSection />
    
    </>
  )
}

export default AllServices