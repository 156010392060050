import React, { useState } from 'react';
import teamImg1 from '../../assets/images/Homeimages/team-img1.jpeg';
import teamImg2 from '../../assets/images/Homeimages/team-img2.jpeg';
import teamImg3 from '../../assets/images/Homeimages/team-img3.jpeg';
import teamImg4 from '../../assets/images/Homeimages/team-img4.jpeg';      
import teamImg5 from '../../assets/images/Homeimages/team-img5.jpeg';  
import teamImg6 from '../../assets/images/Homeimages/team-img6.jpeg';
import teamImg7 from '../../assets/images/Homeimages/team-img7.png';   
import teamImg8 from '../../assets/images/Homeimages/team-img8.jpeg';

import teamImg11 from '../../assets/images/Homeimages/team-img11.jpeg';
import teamImg21 from '../../assets/images/Homeimages/team-img21.jpeg';
import teamImg31 from '../../assets/images/Homeimages/team-img31.jpeg';
import teamImg41 from '../../assets/images/Homeimages/team-img41.jpeg';      
import teamImg51 from '../../assets/images/Homeimages/team-img51.jpeg';  
import teamImg61 from '../../assets/images/Homeimages/team-img61.jpeg';
import teamImg71 from '../../assets/images/Homeimages/team-img71.png';   
import teamImg81 from '../../assets/images/Homeimages/team-img81.jpeg';
import '../../assets/bootstrap/bootstrap.min.css';
import '../../assets/css/aos.css';
import '../../assets/css/custom.css';
import '../../assets/css/mobile.css';
import '../../assets/css/owl.carousel.css';
import '../../assets/css/custom-style.css';
import '../../assets/css/responsive.css';
import '../../assets/css/special-classes.css';

const teamMembers = [
    {
        img: teamImg8,
        mimg: teamImg81,
        name: 'Jolene Gregory',
        title: 'Managing Director, Local Primary Care',
        desc: 'Jolene Gregory is the Co-Managing Director of Local Primary Care with over 25 years of experience in general practice, where she started her career. She is passionate about both general practice and patient care. Jolene has a proven track record in creating and managing innovative general practice and primary care services at scale. She has led programs that enhance healthcare delivery, align clinical systems and processes to reduce unwarranted variation, and integrate pathways of care with various health providers. Jolene specializes in streamlining general practice operations, improving access and patient outcomes, and supporting practice resilience by fostering collaborative partnerships within general practice and Primary Care Networks (PCNs).'
    },
  
    {
        img: teamImg6,
        mimg: teamImg61,
        name: 'Dr Ridwaan Ahmed',
        title: 'General Practitioner and Clinical Director – North PCN',
        desc: "Ridwaan Ahmed is a respected GP and clinical leader in the East Primary Care Network (PCN). His work focuses on improving patient outcomes by enhancing the quality of healthcare services offered within the network. With years of experience, he plays a pivotal role in driving forward strategic healthcare initiatives."
    },
    {
        img: teamImg2,
        mimg: teamImg21,
        name: 'Julie Kenyon',
        title: 'Chief Pharmacist and Managing Director',
        desc: "Julie Kenyon is the Co-Managing Director and Chief Pharmacist of Local Primary Care, with over 30 years of experience in the NHS. She brings a wealth of experience to Local Primary Care, working closely with General Practice, Primary Care Networks (PCNs), and developing the PCN workforce. Julie has demonstrated success in optimizing patient care across both secondary and primary care settings, having held significant commissioning and provider roles. She has been instrumental in the transformation of medicines and pharmacy services, including the development of innovative deployment models across multiple Primary Care Networks (PCNs). Julie has led numerous large-scale projects addressing health inequalities and ensuring the highest standards of patient care."
    },
    {
        img: teamImg7,
        mimg: teamImg71,
        name: 'Dr Tanveer Ahmed',
        title: 'General Practitioner and Director',
        desc: "Dr Tanveer Ahmed has been a dedicated GP in the local community for the past 16 years. He serves as a partner at Shifa Surgery and is the Medical Director for Albion Mill Intermediate Care. Dr Ahmed’s areas of expertise include diabetes, research, and digital innovation in healthcare. His commitment to improving patient outcomes and exploring new healthcare technologies drives his practice."
    },
    {
        img: teamImg4,
        mimg: teamImg41,
        name: 'Dr Hussain Moosa',
        title: 'General Practitioner and Clinical Director – West PCN',
        desc: "Born and raised in Blackburn, Dr Hussain Moosa obtained his medical degree from the University of Liverpool and completed his GP training in East Lancashire. He currently serves as a GP partner in Blackburn and is the Clinical Director for the West Primary Care Network. Dr Moosa is also the Palliative and End of Life GP Lead for Pennine Lancashire, as well as Lancashire and South Cumbria, providing clinical leadership to the Strategic Clinical Network. His dedication to compassionate care at critical life stages is a hallmark of his practice."
    },
    {
        img: teamImg3,
        mimg: teamImg31,
        name: 'Dr Adam Black',
        title: 'General Practitioner and Clinical Director – East PCN',
        desc: "Raised in the close-knit community of Orkney, Dr Adam Black trained as a doctor and GP in Glasgow before embarking on three years of voluntary work in India. As a partner at Cornerstone Practice, Dr Black has been instrumental in the opening of Bentham Road Health Centre and currently practices at Shadsworth Surgery. In addition to his clinical work, Dr Black has extensive experience in NHS management, serving within the Clinical Commissioning Group. He holds the position of Clinical Director for Blackburn with Darwen East Primary Care Network, where he continues to lead with a focus on patient-centred care."
    },
    {
        img: teamImg1,
        mimg: teamImg11,
        name: 'Dr Pervez Muzaffar',
        title: 'General Practitioner',
        desc: "Dr Pervez Muzaffar graduated from Dow Medical College in Karachi, Pakistan, before moving to the UK, where he gained extensive experience in Accident & Emergency departments across various hospitals. After completing his GP training regionally, Dr Muzaffar joined Darwen Healthlink as a GP partner in 2004. His commitment to primary care and patient wellbeing has made him a respected figure in the community, where he continues to provide high-quality healthcare services."
    },
    {
        img: teamImg5,
        mimg: teamImg51,
        name: 'Dr Muhammad Umer',
        title: 'General Practitioner and Clinical Director',
        desc: "Dr Mohammed Umer was born and raised in Blackburn with Darwen and is a GP Partner at Darwen Healthcare. He holds key leadership roles, including Chair of the Local Primary Care GP Federation and Lead Clinical Director for Blackburn with Darwen's Four Primary Care Networks. Additionally, he serves as the Clinical and Care Professional Lead for Blackburn with Darwen, focusing on working across organisations and with different specialists to improve population health in Lancashire and South Cumbria.Graduating from St. Andrew's and Manchester University’s in 2006, Mohammed completed his GP training in East Lancashire in 2011. His leadership has driven innovations in healthcare delivery, addressing health inequalities and improving access to care in the community."
    },
];


const TeamSection = () => {
    const [hoveredIndex, setHoveredIndex] = useState(null);
    return (
        <section className="team-main-section w-100 float-left padding-top padding-bottom light-bg">
        <div className="container">
            <div className="generic-title text-center">
                <span className="small-text" data-aos="fade-up" data-aos-duration="700">Board Members</span>
                <h2 className="mb-0" data-aos="fade-up" data-aos-duration="700">Our Board Members Team</h2>
            </div>

            <div className="team-inner-section four-member-in-row">
                {teamMembers.map((member, index) => {
                    const modalId = `exampleModal${index}`;
                    return (
                        <React.Fragment key={index}>
                            <div className="member-con text-center"
                                 data-bs-toggle="modal"
                                 data-bs-target={`#${modalId}`}
                                 onMouseEnter={() => setHoveredIndex(index)}
                                 onMouseLeave={() => setHoveredIndex(null)}
                                 style={{
                                    cursor: "pointer",
                                    position: "relative",
                                    overflow: "hidden",
                                }}>
                                <div className="member-img-details position-relative">
                                    <figure data-aos="fade-up" data-aos-duration="700" style={{ position: "relative", margin: 0 }}>
                                        <img
                                            src={member.img}
                                            alt={`team-img${index + 1}`}
                                            style={{
                                                width: "100%",
                                                height: "auto",
                                                borderRadius: "10px"
                                            }}
                                        />
                                        {/* Overlay that appears on hover */}
                                        <div
                                            style={{
                                                position: "absolute",
                                                top: 0,
                                                left: 0,
                                                width: "100%",
                                                height: "100%",
                                                backgroundColor: "rgba(0, 0, 0, 0.5)", // Dark transparent overlay
                                                color: "white",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                opacity: hoveredIndex === index ? 1 : 0, // Conditionally show overlay on hover
                                                transition: "opacity 0.3s ease", // Smooth transition
                                                borderRadius: "10px",
                                            }}
                                        >
                                            <p style={{ margin: 0, fontSize: "16px", fontWeight: "bold" }}>
                                                Click to preview details
                                            </p>
                                        </div>
                                    </figure>
                                </div>
                                <h4 data-aos="fade-up" data-aos-duration="700">{member.name}</h4>
                                <span className="d-block" data-aos="fade-up" data-aos-duration="700">{member.title}</span>
                            </div>
                            <Modal title={member.name} desc={member.desc} modalId={modalId} imgsrc={member.mimg}/>
                        </React.Fragment>
                    );
                })}
            </div>
        </div>
    </section>
    );
};

export default TeamSection;


const Modal = ({ title, desc, modalId, imgsrc }) => {
    return (
        <div
            className="modal fade"
            id={modalId}
            tabIndex={-1}
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content">
                 
                    <div className="modal-header">
                        <h5 className="modal-title" style={{marginLeft: "auto", marginRight: "auto", fontWeight: "bold"}} id="exampleModalLabel">
                            {title}
                        </h5>
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            style={{ border: "none", background: "none", fontSize: "19px", cursor: "pointer", color: "red" }}
                        >&#10006;</button>
                    </div>
                    <div style={{marginLeft: "auto" , marginRight: "auto"}}>
                        <img src={imgsrc} height={"120"} width={"120"} style={{borderRadius: "50%"}} alt="" srcset="" />
                    </div>
                    <div className="modal-body p-4" style={{color: "black"}}>
                        {desc}
                    </div>
                </div>
            </div>
        </div>
    );
};
