import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import '../assets/bootstrap/bootstrap.min.css';
import '../assets/css/aos.css';
import '../assets/css/custom.css';
import '../assets/css/mobile.css';
import '../assets/css/owl.carousel.css';
import logo from '../assets/images/LPClogo.png';
import phone from '../assets/images/phone.png';
import { Link } from 'react-router-dom';

function Header() {
  const navigate = useNavigate();
  const location = useLocation();

  const scrollToSection = (id) => {
    navigate(`/#${id}`);
  };

  useEffect(() => {
    if (location.hash) {
      const elementId = location.hash.substring(1);
      const scrollToElement = () => {
        const element = document.getElementById(elementId);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      };

      // Add a slight delay to ensure content is fully loaded before scrolling
      const timer = setTimeout(scrollToElement, 100); // 100ms delay

      return () => clearTimeout(timer);
    }
  }, [location]);

  return (
    <div className="header-main-con w-100 float-left">
      <div className="container-fluid">
        <nav className="navbar navbar-expand-lg navbar-light p-0">
          <Link className="navbar-brand" to="/">
            <figure className="mb-0">
              <img src={logo} width="300" alt="logo" />
            </figure>
          </Link>
          <button class="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"><span class="navbar-toggler-icon"></span><span class="navbar-toggler-icon"></span><span class="navbar-toggler-icon"></span></button>
          <div className="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link to="/" className="nav-link p-0" style={{fontSize: "17px"}} role="button" aria-haspopup="true" aria-expanded="false">
                  Home
                </Link>
              </li>
              <li className="nav-item dropdown">
                <Link className="nav-link p-0" to="/services" role="button" aria-haspopup="true" aria-expanded="false" style={{fontSize: "17px"}}>
                  Our services
                </Link>
                <ul className="dropdown-menu">
                  <li className="dropdown-submenu">
                    <a className="dropdown-item dropdown-toggle">Clinical Services</a>
                    <ul className="dropdown-menu">
                      <li>
                        <Link className="dropdown-item" to="/enhancedaccess">
                          Enhanced Access Clinics
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to={"/cervicalscreening"}>
                          Cervical Screening
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/longcovid">
                          Long COVID Service
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/homecaremedicine">
                          Care Home Medicines Training
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/virtualcarehome">
                          Care Home Virtual Ward Rounds
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/vaccination">
                          Vaccination Service
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="dropdown-submenu">
                    <a className="dropdown-item dropdown-toggle">Administrative Services</a>
                    <ul className="dropdown-menu">
                      <li>
                        <Link className="dropdown-item" to="/workflow">
                          Workflow Hub
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/prescriptionhub">
                          Prescription Hub
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/claimservice">
                          Claims Service
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/secretarialhub">
                          Secretarial Hub
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/practicesupport">
                          Practice Support Service
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/receptionsupport">
                          Reception Support Service
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="dropdown-submenu">
                    <a className="dropdown-item dropdown-toggle">Professional Development and Training</a>
                    <ul className="dropdown-menu">
                      <li>
                        <Link className="dropdown-item" to="/professionaltraining">
                          Professional Training (CPD accredited)
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/corenavigation">
                          Care Navigation Training
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/">
                          Quality Contract Support Service
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="dropdown-submenu">
                    <a className="dropdown-item dropdown-toggle">Workforce Support</a>
                    <ul className="dropdown-menu">
                      <li>
                        <Link className="dropdown-item" to="/workforce">
                          Workforce Deployment & Support Service
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/peoplemanage">
                          People Management Services
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/arrs">
                          ARRS
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle p-0" href="" id="navbarDropdown5" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{fontSize: "17px"}}>
                  About
                </a>
                <div className="dropdown-menu" aria-labelledby="navbarDropdown5">
                  <Link className="dropdown-item" to="/about">About us</Link>
                  <Link className="dropdown-item" to="/board">Board</Link>
                  {/* <Link className="dropdown-item" to="/team">Team</Link> */}
                  {/* Add more about links here */}
                </div>
              </li>
              <li className="nav-item">
                <Link to="/successstories" className="nav-link p-0" role="button" aria-haspopup="true" aria-expanded="false" style={{fontSize: "17px"}}>
                  Success Stories
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link p-0" to="/lpcfederate" role="button" aria-haspopup="true" aria-expanded="false" style={{fontSize: "17px"}}>
                  LPC Federate
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link p-0" to="/#contact" onClick={() => scrollToSection('contact')} style={{fontSize: "17px"}}>Contact</Link>
              </li>
            </ul>
            <div className="header-contact d-flex align-items-center">
              <div className="phone d-flex align-items-center">
                <figure className="mb-0">
                  <img src={phone} alt="phone" />
                </figure>
                <div className="phone-txt">
                  <span className="d-block">Call us now:</span>
                  <a href="tel:01254961018">01254961018</a>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="cart-box">
            <a href="#"><i className="fas fa-search"></i></a>
            
          </div> */}
        </nav>
      </div>
    </div>
  );
}

export default Header;
