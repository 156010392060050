import React from 'react';
// import ServicesSideSection from './ServicesSideSection'; // Assuming ServicesSideSection is another React component
import '../../assets/bootstrap/bootstrap.min.css'
import '../../assets/css/aos.css'
import '../../assets/css/custom.css';
import '../../assets/css/mobile.css';
import '../../assets/css/owl.carousel.css';
import '../../assets/css/custom-style.css';
import '../../assets/css/responsive.css';
import '../../assets/css/special-classes.css';
import img1 from "../../assets/images/Homeimages/enhanced.webp"
import ServiceSideSection from '../serviceSideSection';
import BannerDynammic from '../BannerD';


const EnhancedAccess = () => {
    
  return (
    <>
  
<BannerDynammic had1={"Enhanced Access Clinics"}/>
    <section className="singleblog-section blogpage-section">
      <div className="container" >
        <div className="row">
          <div className=" col-md-12 col-sm-12 col-12">
            <div className="main-box">
              <figure className="image1" data-aos="fade-up" data-aos-duration="700">
                <img src={img1} alt="" className="img-fluid" />
              </figure>
              <div className="content1" data-aos="fade-up" data-aos-duration="700">
                <p className="text-size-16">
                  Enhanced Access Clinics offer patients greater flexibility and convenience in accessing primary
                  healthcare services. With extended operating hours, same-day appointments, and alternative consultation
                  options such as telemedicine, our clinics ensure that patients receive timely and comprehensive care
                  when needed.
                  <br /><br />
                  Our team of healthcare professionals is committed and dedicated to providing personalised and
                  patient-centred care. We understand that your health is unique, and we are here to address your acute
                  and chronic healthcare needs with compassion and expertise. By offering enhanced access to primary care
                  services, we aim to reduce pressure on partner surgeries and reduce unnecessary emergency department
                  visits and hospital admissions, improving overall healthcare outcomes and patient satisfaction.
                  <br /><br />
                  Our Enhanced Access Clinics are here to serve our patients, promptly ensuring they receive support
                  for their urgent needs.
                  <br /><br />
                  In Blackburn with Darwen, we currently support the following regions:
                  <ul style={{listStyle: "none"}}>
                    <li>Blackburn East</li>
                    <li>Blackburn North</li>
                    <li>Blackburn West</li>
                    <li>Darwen</li>
                  </ul>
                </p>
              </div>
            </div>
          </div>
          {/* <div className="col-lg-4 col-md-12 col-sm-12 col-12 column">
          </div> */}
        </div>
      </div>
      <ServiceSideSection />

    </section>
    </>

  );
};

export default EnhancedAccess;
